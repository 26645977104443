// src/componentes/AppRouter/AppRoutes.js

import React, {useState, useEffect, useCallback, useMemo, memo} from 'react';
import {Route, Routes, Navigate, Outlet, Link, useNavigate, useLocation, useSearchParams} from 'react-router-dom';
import {FaMapLocationDot} from "react-icons/fa6";
import {SiMaplibre} from "react-icons/si";
import {BsPersonCircle} from "react-icons/bs";
import {PiMapPinAreaBold} from "react-icons/pi";
import {IoArrowBackCircleOutline} from "react-icons/io5";
import {useTheme} from "../ThemeContext/ThemeContext";  // Import useTheme hook

import styles from './AppRouter.module.css';
import logo from '../../assets/logo2.svg';
import logoLight from '../../assets/logo.svg';
import logoBeta from '../../assets/logo-beta.svg';
import logoBetaLight from '../../assets/logo-beta-light.svg';

import BusinessProfile from "../BusinessProfile/BusinessProfile";
import Results from "../Results/Results";
import IconSquare from "../IconSquare/IconSquare";
import MainNav from "../MainNav/MainNav";
import DealPostForm from "../DealPostForm/DealPostForm";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import authApi from '../../utils/auth.api';
import Login from "../Auth/Login";
import Register from "../Auth/Register";
import LocationSettingsMenu from "../LocationSettingsMenu/LocationSettingsMenu";

// New components (you'll need to create these)
import DealManager from "../AdminSettings/DealManager/DealManager";
import LocationManager from "../AdminSettings/LocationManager/LocationManager";
import ActiveDeals from "../AdminSettings/ActiveDeals/ActiveDeals";
import InactiveDeals from "../AdminSettings/InactiveDeals/InactiveDeals";
import Locations from "../AdminSettings/Locations/Locations";
import LocationSettings from "../AdminSettings/LocationSettings/LocationSettings";
import UserAdminManager from "../AdminSettings/UserAdminManager/UserAdminManager";
import LocationDashboard from "../AdminSettings/LocationDashboard/LocationDashboard";
import SubscriptionSettings from "../AdminSettings/SubscriptionSettings/SubscriptionSettings";

// Super Admin Components
import SuperAdminDashboard from "../SuperAdminSettings/SuperAdminDashboard/SuperAdminDashboard";
import ManageDispensaries from "../SuperAdminSettings/ManageDispensaries/ManageDispensaries";
import ManageUsers from "../SuperAdminSettings/ManageUsers/ManageUsers";
import AssignUserToDispensary from "../SuperAdminSettings/AssignUserToDispensary/AssignUserToDispensary";
import SuperAdminSettingsMenu from "../SuperAdminSettings/SuperAdminSettingsMenu/SuperAdminSettingsMenu";
import UserSettingsMenu from "../UserSettingsMenu/UserSettingsMenu";
import LocationAdminMenu from "../LocationAdminMenu/LocationAdminMenu";
import SearchApi from "../Search/Search";
import Chat from "../Chat/Chat";
import {NavVisibilityProvider} from "../NavVisibilityContext/NavVisibilityContext";
import AnimatedSearchIndicator from "../Icons/AnimatedSearchIndicator/AnimatedSearchIndicator";
import IntroTour from "../IntroTour/IntroTour";
import UserSelectFavoriteProducts from "../UserSelectFavoriteProducts/UserSelectFavoriteProducts";
import UserNearbyDispensaries from "../UserNearbyDispensaries/UserNearbyDispensaries";
import useApi from "../../utils/useApi";
import UserSelectFavoriteBrands from "../UserSelectFavoriteBrands/UserSelectFavoriteBrands";
import {BrandProvider, useBrands} from '../BrandContext/BrandContext';
import DispensaryAdminMenu from "../DispensaryAdminMenu/DispensaryAdminMenu";
import BackgroundMedia from "../Auth/BackgroundMedia/BackgroundMedia";
import LocationHistoryListbox from "../LocationHistoryListbox/LocationHistoryListbox";
import LocationAutocompleteBottomSheet from "../LocationAutocompleteBottomSheet/LocationAutocompleteBottomSheet";
import Notifications from "../Notifications/Notifications";
import ProductPreview from "../ProductPreview/ProductPreview";
import {createCryptoInterceptor} from "../../utils/crypto-interceptor";
import {NavCountProvider} from "../../contexts/NavCountProvider";
import {useGeolocation} from "../../contexts/GeolocationProvider";
import LoginBottomSheet from "../Auth/LoginBottomSheet/LoginBottomSheet";
import eventBus from "../../utils/eventBus";
import ManageOnboardingBrands from "../AdminSettings/ManageOnboardingBrands/ManageOnboardingBrands";
import useNotifications from "../../hooks/useNotifications";
import AccountSettings from "../AccountSettings/AccountSettings";
import {HighlightDispensaryProvider} from "../../contexts/HighlightDispensaryProvider";
import GoogleAuthCallback from "../Auth/GoogleAuthCallback/GoogleAuthCallback";
import DealBottomSheetV2 from "../DealBottomSheet/DealBottomSheetV2";
import {useUserActions} from "../../contexts/UserActionsContext";
import WelcomeScreen from "../WelcomeScreen/WelcomeScreen";
import {PWAInstallProvider} from "../../contexts/PWAInstallContext";
import InstallBanner from "../InstallBanner/InstallBanner";
import InstallFAB from "../InstallBanner/InstallFAB";  // Add this import

const AppRoutesContent = (props) => {
    const [isBeta, setIsBeta] = useState(true);
    const [showWelcomeScreen, setShowWelcomeScreen] = useState(false);
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const [isLoginSheetOpen, setIsLoginSheetOpen] = useState(false);
    const { geoposition, changeGeoPosition, changeLocation } = useGeolocation();
    const { actionCounts, trackVisitCount } = useUserActions();
    const [showDate, setShowDate] = useState(true);
    const [showHeader, setShowHeader] = useState(true);
    const [showSettingsHeader, setShowSettingsHeader] = useState(false);
    const [unauthorizedData, setUnauthorizedData] = useState(null);
    const { permission, subscribeUser } = useNotifications();
    const [pushSubscribed, setPushSubscribed] = useState(false);

    const [productId, setProductId] = useState(null);
    const [productOpen, setProductOpen] = useState(null);
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [route, setRoute] = useState('/');
    const [isLoading, setIsLoading] = useState(true);
    const [showBottomSheet, setShowBottomSheet] = useState(false);
    const [locationInput, setLocationInput] = useState('');
    const { isDarkMode } = useTheme();  // Use the useTheme hook
    const [logoSrc, setLogoSrc] = useState(isDarkMode ? logo : logoLight);  // Choose logo based on theme
    const backgroundMediaColor = isDarkMode ? "rgba(0,0,0,0.72)" : "rgba(255,255,255,0.81)";
    const navigate = useNavigate();
    const api = useApi();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [showTour, setShowTour] = useState(false);
    const { isNavVisible, setIsNavVisible } = NavVisibilityProvider;
    const { loadBrands } = useBrands();  // Get loadBrands from context
    const [appPath, setAppPath] = useState('');

    // Initialize auth state on mount
    useEffect( () => {


        initializeAuth();

        if (isBeta) {
            if (isDarkMode) {
                setLogoSrc(logoBeta);
            } else {
                setLogoSrc(logoBetaLight)
            }
        }
    }, []);


    useEffect(() => {
        let path = location.pathname;
        path = path.replace('/', '');
        path = path.replace('-', '');
        setAppPath(path);
    }, [location.pathname]);

    useEffect(() => {
        console.log(searchParams);
        if (searchParams.get('product')) {
            console.log(searchParams.get('product'))
            setProductId(searchParams.get('product'));
            setProductOpen(true);
        }
    }, []);

    const askForNotifications = async () => {
        const result = await subscribeUser();
        if (result) {
            setPushSubscribed(true);
            console.log('Successfully subscribed to push notifications');
        }
    };

    useEffect(() => {
        console.log("push subscribed:", pushSubscribed);
        console.log("user:", user);
        console.log("permission:", permission);
        if (user && permission === 'default' && !pushSubscribed) {
            askForNotifications();
        }

    }, [user, permission, subscribeUser]);



    const initializeAuth = async () => {
        try {
            setIsLoading(true);
            const currentUser = await authApi.getCurrentUser();

            console.log(currentUser);
            if (currentUser) {
                setUser(currentUser);
                // Remove the automatic navigation to home
                if (props.geoposition) {
                    loadBrands(api, props.geoposition);
                }

                console.log(location);
                console.log(location.pathname);

            }

            console.log('NAVIGATING');

            if (location.pathname.includes('index.html')) {
                navigate('/');
            } else {
                navigate(location.pathname + location.search);
            }

            if(actionCounts.visitCount === 0 && !currentUser){
                handleFirstVisit();
            }

        }
        catch (error) {
            console.error('Auth initialization error:', error);
            setError(error);
        }
        finally {
            setIsLoading(false);


        }
    };

    const handleFirstVisit = () => {
        //setShowWelcomeScreen(true);
        setIsLoginSheetOpen(true);
        trackVisitCount();
    }

    const handleCloseWelcome = () => {
        setShowWelcomeScreen(false);
        trackVisitCount();
    };

    const handleAgeConfirm = (isOver21) => {
        // Store the age confirmation status
        localStorage.setItem('isOver21', isOver21.toString());

        if (!isOver21) {
            // If user is not 21+, you might want to redirect or show different content
            handleCloseWelcome();
            window.location.href = 'https://www.toysrus.com/';
        }  else {
            handleCloseWelcome();
        }
    };

    useEffect(() => {
        // Listen for the "unauthorized" event
        eventBus.on('unauthorized', handleUnauthorized);

        // Cleanup
        return () => {
            eventBus.off('unauthorized', handleUnauthorized);
        };
    }, []);

    const handleUnauthorized = (data) => {
        console.log("Unauthorized");
        console.log(data);

        setIsLoginSheetOpen(true);
        setUnauthorizedData(data);
    }

    const tourSteps = [
        {
            element: '.near_you',
            intro: 'Discover deals closest to your location.',
            position: 'bottom'
        },
        {
            element: '.best_deal',
            intro: 'Find the best deals sorted by the cheapest price within your search radius.',
            position: 'bottom'
        },
        {
            element: '.tour-product-item',
            intro: 'This is your Deals Feed. Click on a deal to view its details and redeem it. You can share and save deals to your favorites.',
            position: 'bottom'
        },
        {
            element: '.for_you',
            intro: 'Deals and products tailored to your menu, brand, and dispensary preferences are showcased here.',
            position: 'bottom'
        },
        {
            element: '.main-nav',
            intro: 'This is your navigation bar, use it to explore and access different sections of the app.',
            position: 'top'
        },
        {
            element: '.home',
            intro: 'This is the main deals feed, find all deals nearest you here.',
            position: 'top',
            customHighlight: 'menuButtonCustomHighlight',
        },
        {
            element: '.search',
            intro: 'Search all products, deals, and dispensaries here.',
            position: 'top',
            customHighlight: 'menuButtonCustomHighlight',

        },
        {
            element: '.deal-alerts',
            intro: 'This is where you receive deal alerts based on the brands you’ve chosen in your brand preferences.',
            position: 'top',
            customHighlight: 'menuButtonCustomHighlightNotify',

        },
        {
            element: '.profile',
            intro: 'This is your account profile. Update your menu, brand, and dispensary preferences here at any time.',
            position: 'top',
            customHighlight: 'menuButtonCustomHighlight',

        },
        {
            element: '.navbar-filter',
            intro: 'This is your deals feed search bar. Change menu categories, see what’s popular and search by strain, brand, and more. Filter your deal feed results using these options: distance, price, weight, strain type, thc and cbd amounts.',
            position: 'bottom'
        },

    ];

    const handleShowHeader = useCallback((show) => {
        console.log("Handling show header: " + show);
        setShowHeader(show);
    }, []);

    const handleShowDate = useCallback((show) => {
        setShowDate(show);
    }, []);

    const handleShowSettingsHeader = (newState) => {
        setShowSettingsHeader(newState);
    }

    const handlePasskeyLogin = (user) => {
        setUser(user);
        if (unauthorizedData?.redirect) {
            window.open(unauthorizedData.redirect, '_blank');
        } else {
            navigate('/');
        }
    }

    const handleLogin = async (email, password, shouldNavigate, route) => {
        try {
            setRoute(route);

            console.log('Handling login');
            const userData = await authApi.login(email, password);
            setUser(userData);

            if (unauthorizedData?.redirect) {
                window.open(unauthorizedData.redirect, '_blank');
            } else if (shouldNavigate) {
                console.log('Navigating Home')
                navigate('/');
            }

            return userData;
        }
        catch (error) {
            console.error('Login failed:', error);
            setError('Login failed. Please check your credentials.');

            return error;
        }
    };

    const handleOtpLogin = async (email, otp) => {
        try {
            setIsLoading(true);
            setError('');

            const userData = await authApi.verifyOtp(email, otp);
            setUser(userData);

            // Navigate to home page
            if (unauthorizedData?.redirect) {
                window.open(unauthorizedData.redirect, '_blank');
            } else {
                navigate('/');
            }
        }
        catch (err) {
            setError(err.message);
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleRegister = async (email, password, firstName, username) => {
        try {
            let response = await authApi.register(email, password, firstName, username);
            console.log(response);

            if (response.success) {
                const shouldNavigate = false;
                const userData = await handleLogin(email, password, shouldNavigate, '/select-dispensaries');
                console.log("Here is the user data", userData);

                if (userData) {

                    // Start loading brands in the background using the context
                    if (props.geoposition) {
                        // This will now cache the brands in the context
                        loadBrands(api, props.geoposition);
                    }

                    handleOnboarding(userData);

                }
            } else {
                console.error('Registration failed:', response.message);
                setError(response.message);
            }
        }
        catch (error) {
            console.error('Registration error:', error);
            setError('An unexpected error occurred. Please try again.');
            console.log(error?.response?.data?.message);
            if (error?.response?.data?.message) {
                setError(error?.response?.data?.message);
            }
        }
    };

    const handleOnboarding = async (user) => {
        setUser(user);
        console.log("Checking onboarding status");
        const onboardingStatus = await api.checkOnboardingStatus();
        console.log(onboardingStatus);

        switch (onboardingStatus.status) {
            case 'new':
                navigate('/select-dispensaries');
                break;
            case 'dispensaries_selected':
                navigate('/select-products');
                break;
            case 'products_selected':
                navigate('/');
                //setShowTour(true);
                break;
            default:
                console.error('Unknown onboarding status:', onboardingStatus.status);
                if (unauthorizedData?.redirect) {
                    window.open(unauthorizedData.redirect, '_blank');
                } else {
                    navigate('/');
                }
        }
    }

    const handleLogout = () => {
        setIsLoggingOut(true);
        authApi.logout();
        setUser(null);
        navigate('/');

        setTimeout(function () {
            setIsLoggingOut(false);
            setIsLoginSheetOpen(false);
        }, 2000);
    };

    const handleCompleteFavoriteBrands = useCallback(() => {
        setShowTour(true);
        loadBrands(api, props.geoposition);

    }, []);

    const toggleBottomSheet = useCallback(() => {
        setShowBottomSheet(prev => !prev);
    }, []);

    useEffect(() => {
        console.log("Current geoposition:", props.geoposition);
    }, [props.geoposition]);

    const renderHeader = () => {
        let thisStyle = {};
        if (!showDate) {
            thisStyle = {
                //height: '69px',
            }
        }
        return (
            <div>
                <div className={styles.header}>
                    <div className={styles.upperHeader} style={thisStyle}>
                        <div className={styles.logoWrap}>
                            <Link to={"/"}>
                                <img src={logoSrc} alt="Logo" className={`${styles.logo} ${isBeta ? styles.logoBeta : ''}`}/>
                            </Link>
                        </div>
                        <div></div>
                        {props.geoposition && props.geoposition.city && (
                            <div className={`flex gap-2 ${styles.headerBtns}`}>
                                {/*!user && (
                                    <Link to='/user-settings' className={`${styles.signInBtn}`}>
                                        Log In or Register
                                    </Link>
                                ) */}

                                <LocationHistoryListbox
                                    geoposition={props.geoposition}
                                    locationLoading={props.locationLoading}
                                    onLocationSelect={(location) => {
                                        changeGeoPosition(false, location);
                                    }}
                                    onAddNewLocation={() => setShowBottomSheet(true)}
                                />
                            </div>

                        )}
                    </div>

                    {showDate && (
                        <div className={styles.lowerHeader}>
                            {props?.dateObject?.currentDate}
                        </div>
                    )}


                </div>
            </div>
        );
    };

    const renderSettingsHeader = () => {
        const getPageName = () => {
            const path = location.pathname;
            const pathParts = path.split('/');
            const lastPart = pathParts[pathParts.length - 1];
            return lastPart.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        };

        return (
            <div>
                <div className={styles.settingsHeader}>
                    <div className={styles.upperSettingsHeader}>
                        <div className={styles.backLink} style={{ width: "33%" }}>
                            <button onClick={() => navigate('/')} className={styles.backButton}>
                                <IoArrowBackCircleOutline/>
                            </button>

                        </div>
                        <div className={styles.pageName} style={{ width: "51%", textAlign: 'center' }}>
                            {getPageName()}
                        </div>
                        <div className={styles.logoWrapFlex} style={{ width: "33%" }}>
                            <Link to={"/"}>

                                <img src={logoSrc} alt="Logo" className={styles.logo} style={{ alignSelf: "end" }}/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const closeProduct = () => {
        setProductOpen(false);
    };

    // New component to protect public routes from authenticated users
    const PublicRoute = memo(({ user }) => {
        if (user) {
            return <Navigate to={route} replace/>;
        }
        return <Outlet/>;
    });

    const closeBottomSheet = () => {
        setShowBottomSheet(false)
    }

    const renderBottomSheet = useMemo(() => (
        <LocationAutocompleteBottomSheet
            isOpen={showBottomSheet}
            onClose={() => closeBottomSheet(event)}
            onLocationSelect={async (location) => {
                try {
                    await changeLocation(`${location.city}, ${location.state_abbr}`);
                    setShowBottomSheet(false);
                }
                catch (error) {
                    console.error("Error changing location:", error);
                }
            }}
            locationLoading={props.locationLoading}
        />
    ), [showBottomSheet, changeLocation, props.locationLoading]);

    const memoizedRoutes = useMemo(() => (
        <Routes>
            <Route element={<PublicRoute user={user}/>}>
                <Route path="/login" element={<Login onLogin={handleLogin} handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} geoposition={props.geoposition}/>}/>
                <Route path="/register" element={<Register onRegister={handleRegister} handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} geoposition={props.geoposition} error={error}/>}/>

            </Route>

            <Route path="/auth/google/callback" element={
                <GoogleAuthCallback
                    setUser={setUser}
                    handleShowHeader={handleShowHeader}
                    handleShowSettingsHeader={handleShowSettingsHeader}
                    handleOnboarding={handleOnboarding}
                />
            }/>

            <Route path="/product/:id" element={<ProductPreview handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
            <Route path="/:dispensarySlug/product/:productSlug/:id" element={<ProductPreview handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>


            <Route path="/search-all" element={<SearchApi handleShowHeader={handleShowHeader} handleShowDate={handleShowDate} handleShowSettingsHeader={handleShowSettingsHeader} geoposition={props.geoposition}/>}/>
            {props.geoposition && (
                <Route exact path="/" element={<Results user={user} handleShowHeader={handleShowHeader}
                                                        handleShowDate={handleShowDate} handleShowSettingsHeader={handleShowSettingsHeader} changeGeoPosition={changeGeoPosition} geoposition={props.geoposition} showTour={showTour} setShowTour={setShowTour}/>}/>
            )}
            {props.geoposition && (
                <Route path="/search" element={<Results handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} changeGeoPosition={changeGeoPosition} geoposition={props.geoposition} showTour={showTour} setShowTour={setShowTour}/>}/>
            )}
            <Route path="/business/:id" element={
                <BusinessProfile
                    handleShowHeader={handleShowHeader}
                    handleShowSettingsHeader={handleShowSettingsHeader}
                    handleShowDate={handleShowDate}
                    showTour={showTour}
                    setShowTour={setShowTour}
                />
            }/>

            <Route element={<ProtectedRoute user={user}/>}>
                <Route path={"/account-settings"} element={<AccountSettings user={user}
                                                                            setUser={setUser}
                                                                            onLogout={handleLogout}
                                                                            handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/dispensary-preferences" element={<UserNearbyDispensaries handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} geoposition={props.geoposition} user={user}/>}/>
                <Route path="/menu-preferences" element={<UserSelectFavoriteProducts handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} geoposition={props.geoposition} user={user}/>}/>
                <Route path="/brand-preferences" element={<UserSelectFavoriteBrands handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} geoposition={props.geoposition} user={user}/>}/>
                <Route path="/deal-alerts" element={<Notifications handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} user={user}/>}/>
                <Route path="/select-dispensaries" element={<UserNearbyDispensaries handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} geoposition={props.geoposition}/>}/>
                <Route path="/select-products" element={<UserSelectFavoriteProducts handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} geoposition={props.geoposition}/>}/>
                <Route path="/select-brands" element={<UserSelectFavoriteBrands handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} geoposition={props.geoposition} onComplete={handleCompleteFavoriteBrands}/>}/>

                {/* Location Settings Routes */}

                <Route path="/location-settings/add-deal" element={<DealPostForm handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/deals/:id/edit-deal" element={<DealPostForm handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/deals/:business_id/add-deal" element={<DealPostForm handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>

                <Route path="/dispensary-admin" element={<DispensaryAdminMenu user={user} handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} handleLogout={handleLogout} setShowTour={setShowTour}/>}/>
                <Route path="/account-admin" element={<LocationAdminMenu user={user} handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} handleLogout={handleLogout}/>}/>
                <Route path="/location-settings/:id/settings" element={<LocationSettingsMenu handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} handleLogout={handleLogout}/>}/>
                <Route path="/location-settings/:id/deal-manager" element={<DealManager handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/dispensary-manager" element={<LocationManager handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/active-deals" element={<ActiveDeals handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/inactive-deals" element={<InactiveDeals handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/list-locations" element={<Locations handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/:id/edit-settings" element={<LocationSettings handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/user-admin" element={<UserAdminManager handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/dashboard" element={<LocationDashboard handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/subscription" element={<SubscriptionSettings handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>

                <Route path="/user-settings" element={<UserSettingsMenu user={user} includeHello={true}
                                                                        includeLogout={true}
                                                                        handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} handleLogout={handleLogout} setShowTour={setShowTour}/>}/>
            </Route>

            <Route element={<ProtectedRoute user={user} requiredRole={['admin', 'superadmin']}/>}>
                <Route path="/location-settings/:id/admins" element={<AssignUserToDispensary handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} user={user}/>}/>
                <Route path="/superadmin/manage-dispensary-admins" element={<AssignUserToDispensary handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} user={user}/>}/>
                <Route path="/superadmin" element={<SuperAdminSettingsMenu handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} user={user}/>}/>
                <Route path="/superadmin/manage-dispensaries" element={<ManageDispensaries handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} user={user}/>}/>
                <Route path="/superadmin/manage-users" element={<ManageUsers handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} user={user}/>}/>
                <Route path="/superadmin/manage-brands" element={<ManageOnboardingBrands handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} user={user}/>}/>
                <Route path="/settings" element={<UserSettingsMenu user={user} includeHello={true}
                                                                   includeLogout={false}
                                                                   handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} handleLogout={handleLogout} setShowTour={setShowTour}/>}/>
            </Route>

            <Route path="*" element={<Navigate to="/" replace/>}/>
        </Routes>

    ), [user, handleLogin, handleRegister, props.geoposition]);

    return (
        <div>
            <NavCountProvider user={user}>
                <NavVisibilityProvider>
                    <LoginBottomSheet
                        isOpen={isLoginSheetOpen && !isLoggingOut}
                        onClose={() => {
                            console.log('Closing login sheet');
                            setIsLoginSheetOpen(false);
                        }}
                        onPasskeyLogin={handlePasskeyLogin}
                        onLogin={handleLogin}
                        onOtpLogin={handleOtpLogin}
                        unauthorizedData={unauthorizedData}
                        handleOnboarding={handleOnboarding}
                    />
                    {productId && (
                        <DealBottomSheetV2 productId={productId} isOpen={productOpen} onClose={closeProduct}></DealBottomSheetV2>
                    )}

                    {showWelcomeScreen && (
                        <WelcomeScreen
                            logoSrc={logoSrc}
                            onClose={handleCloseWelcome}
                            onAgeConfirm={handleAgeConfirm}
                            onOpenLogin={setIsLoginSheetOpen}
                        />
                    )}

                    <BackgroundMedia geoposition={props.geoposition} customStyle={{ backgroundColor: backgroundMediaColor }}/>

                    {(showTour && location.pathname == '/' && user && props.geoposition) && (
                        <IntroTour
                            steps={tourSteps}
                            onComplete={() => {
                                setShowTour(false); // Ensure nav is visible when tour completes
                            }}
                            delay={1000}
                        />
                    )}

                    {showHeader && renderHeader()}
                    {showSettingsHeader && renderSettingsHeader()}
                    {renderBottomSheet}

                    {showSettingsHeader ? (
                        <div className={styles.settingsContent}>
                            {memoizedRoutes}
                        </div>
                    ) : memoizedRoutes}

                    {(showTour) && (<div className={`${styles.Navigation} ${styles.visible} main-nav-tour`}>
                        <MainNav
                            customClass={`${styles.mainNavTour} ${appPath}`}
                            user={user}
                            onLogout={handleLogout}
                            showTour={showTour}
                            setShowTour={setShowTour}
                        />
                    </div>)}

                    {(!showTour) && (<div className={`${styles.Navigation} ${isNavVisible} main-nav-normal`}>
                        <MainNav
                            user={user}
                            onLogout={handleLogout}
                            showTour={showTour}
                            setShowTour={setShowTour}
                        />
                    </div>)}


                </NavVisibilityProvider>
            </NavCountProvider>
        </div>
    );
};

const AppRoutes = (props) => {
    return (
        <PWAInstallProvider>
            <InstallBanner
                title="Install Pluug"
                description="Get the best experience by installing our app on your device"
                requireUserEngagement={true}
                delay={5000}
            />
            <BrandProvider>
                <HighlightDispensaryProvider>
                    <AppRoutesContent {...props} />
                </HighlightDispensaryProvider>
            </BrandProvider>
        </PWAInstallProvider>
    )
}

export default React.memo(AppRoutes);
