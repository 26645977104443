import React, { useState, useEffect } from 'react';
import useApi from '../../../utils/useApi';
import { PlusCircle, Trash2 } from 'lucide-react';
import { debounce } from 'lodash';
import { states } from "../../../utils/states";

const ManageOnboardingBrands = () => {
    const [selectedState, setSelectedState] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [brands, setBrands] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [order, setOrder] = useState('');
    const { searchBrands, onboardBrand, getStateBrands, updateBrandOrder, isLoading } = useApi();

    // Load selected brands when state changes
    useEffect(() => {
        if (selectedState) {
            getStateBrands(selectedState)
                .then(response => {
                    setSelectedBrands(response.data);
                })
                .catch(error => {
                    console.error('Error loading state brands:', error);
                });
        } else {
            setSelectedBrands([]);
        }
    }, [selectedState, getStateBrands]);

    const debouncedSearch = debounce(async (term) => {
        if (term.length >= 2) {
            try {
                const response = await searchBrands(term);
                setBrands(response.data);
            } catch (error) {
                console.error('Error searching brands:', error);
            }
        }
    }, 300);

    useEffect(() => {
        debouncedSearch(searchTerm);
        return () => debouncedSearch.cancel();
    }, [searchTerm]);


    const handleBrandSelect = (brand) => {
        setSelectedBrand(brand);
        // If the brand is already selected, set the current order
        const existingBrand = selectedBrands.find(b => b.id === brand.id);
        if (existingBrand) {
            setOrder(existingBrand.order.toString());
        } else {
            setOrder('');
        }
    };

    const handleSubmit = async () => {
        if (selectedBrand && selectedState && order) {
            try {
                // Check if brand is already selected
                const existingBrand = selectedBrands.find(b => b.id === selectedBrand.id);

                if (existingBrand) {
                    // Update existing brand order
                    const response = await updateBrandOrder(selectedState, selectedBrand.id, parseInt(order));
                    setSelectedBrands(response.data);
                } else {
                    // Add new brand
                    await onboardBrand({
                                           state: selectedState,
                                           brand_id: selectedBrand.id,
                                           order: parseInt(order),
                                           include_in_onboarding: true
                                       });

                    // Refresh the selected brands list
                    const response = await getStateBrands(selectedState);
                    setSelectedBrands(response.data);
                }

                // Reset form
                setSelectedBrand(null);
                setOrder('');
            } catch (error) {
                console.error('Error updating brand:', error);
            }
        }
    };

    return (
        <div className="w-full p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg box-border">
            {/* State Selector */}
            <select
                value={selectedState}
                onChange={(e) => setSelectedState(e.target.value)}
                className="w-full p-2 mb-4 rounded-lg border border-gray-300 dark:border-gray-600
                          bg-white dark:bg-gray-700 text-gray-900 dark:text-white
                          focus:ring-2 focus:ring-[#98fb98] dark:focus:ring-[#775496]"
            >
                {states.map(state => (
                    <option key={state.value} value={state.value}>
                        {state.label}
                    </option>
                ))}
            </select>

            {/* Search Input */}
            <div className="mb-6">
                <input
                    type="text"
                    placeholder="Search brands..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full p-2 rounded-lg border border-gray-300 dark:border-gray-600
                             bg-white dark:bg-gray-700 text-gray-900 dark:text-white
                             focus:ring-2 focus:ring-[#98fb98] dark:focus:ring-[#775496]"
                />
            </div>

            {/* Search Results Grid */}
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-6">
                {brands.map(brand => (
                    <div
                        key={brand.id}
                        onClick={() => handleBrandSelect(brand)}
                        className={`p-4 rounded-lg cursor-pointer transition-all
                                  ${selectedBrand?.id === brand.id ?
                                    'bg-[#98fb98] dark:bg-[#775496]' :
                                    'bg-gray-100 dark:bg-gray-700 hover:bg-[#98fb98]/20 dark:hover:bg-[#775496]/20'}`}
                    >
                        <div className="aspect-square relative mb-2">
                            <img
                                src={brand.logo}
                                alt={brand.name}
                                className="w-full h-full object-contain rounded-lg"
                            />
                        </div>
                        <p className="text-center text-sm font-medium text-gray-900 dark:text-white">
                            {brand.name}
                        </p>
                    </div>
                ))}
            </div>

            {/* Order Input and Submit */}
            {selectedBrand && (
                <div className="flex gap-4 items-center mb-8">
                    <label for="order">Set Brand List Order</label>
                    <input
                        type="number"
                        name="order"
                        placeholder="Set order"
                        value={order}
                        onChange={(e) => setOrder(e.target.value)}
                        className="flex-1 p-2 rounded-lg border border-gray-300 dark:border-gray-600
                                 bg-white dark:bg-gray-700 text-gray-900 dark:text-white
                                 focus:ring-2 focus:ring-[#98fb98] dark:focus:ring-[#775496]"
                    />
                    <button
                        onClick={handleSubmit}
                        disabled={!order || isLoading}
                        className="p-2 rounded-lg bg-[#98fb98] dark:bg-[#775496]
                                 text-gray-900 dark:text-white
                                 border-0
                                 hover:opacity-90 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        <PlusCircle className="w-6 h-6" />
                    </button>
                </div>
            )}

            {/* Selected Brands Section */}
            {selectedState && selectedBrands.length > 0 && (
                <div className="mt-8">
                    <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">
                        Selected Brands for {selectedState}
                    </h3>
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        {selectedBrands.map(brand => (
                            <div
                                key={brand.id}
                                onClick={() => handleBrandSelect(brand)}
                                className={`p-4 rounded-lg cursor-pointer transition-all
                              ${selectedBrand?.id === brand.id ?
                                'bg-[#98fb98] dark:bg-[#775496]' :
                                'bg-gray-50 dark:bg-gray-700 hover:bg-[#98fb98]/20 dark:hover:bg-[#775496]/20'}`}
                            >
                                <div className="aspect-square relative mb-2">
                                    <img
                                        src={brand.logo}
                                        alt={brand.name}
                                        className="w-full h-full object-contain rounded-lg"
                                    />
                                </div>
                                <p className="text-center text-sm font-medium text-gray-900 dark:text-white">
                                    {brand.name}
                                </p>
                                <p className="text-center text-xs text-gray-500 dark:text-gray-400">
                                    Order: {brand.order}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ManageOnboardingBrands;
