import React, {useEffect, useState} from 'react';
import {User, Mail, Lock, MapPin, ChevronRight, ArrowLeft, Loader2} from 'lucide-react';
import OTPInput from "../Auth/OtpInput/OtpInput";
import useApi from "../../utils/useApi";
import authApi from "../../utils/auth.api";
import {BsFillPersonVcardFill} from "react-icons/bs";
import Swal from 'sweetalert2';
import {Link, useNavigate} from "react-router-dom";
import styles from "../UserSettingsMenu/UserSettingsMenu.module.css";
import {CiLogout} from "react-icons/ci";
import constants from "../../utils/constants";

const AccountSettings = ({ handleShowHeader, handleShowSettingsHeader, user, setUser, handleBack, onLogout }) => {
    const {deleteAccount, updateAccount, verifyPasswordOtp} = useApi();
    const [name, setName] = useState(user?.name || '');
    const [username, setUsername] = useState(user?.username || '');
    const [email, setEmail] = useState(user?.email || '');
    const [location, setLocation] = useState(user?.location || '');
    const [showPasswordChange, setShowPasswordChange] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showOtp, setShowOtp] = useState(false);
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        handleShowSettingsHeader(true);
        handleShowHeader(false);
    }, []);

    const handleSubmit = async (e) => {
        e?.preventDefault();
        setSaving(true);
        try {
            const data = {
                name: name,
                username: username,
                email: email,
                location: location,
            }

            if(confirmPassword){
                data.password = confirmPassword;
            }

            const response = await updateAccount(data);
            const newUserDetails = response.user;
            setSuccess('Profile updated successfully');

            // Get current user from localStorage
            const localUser = JSON.parse(localStorage.getItem('user'));

            // Merge the new user details with existing localStorage data
            const updatedUser = {
                ...localUser,
                ...newUserDetails
            };

            setUser(updatedUser);
            console.log(updatedUser);

            // Update localStorage with new user details
            localStorage.setItem('user', JSON.stringify(updatedUser));

            setTimeout(() => setSuccess(''), 3000);
        } catch (err) {
            setError('Failed to update profile');
            setTimeout(() => setError(''), 3000);
        }

        setSaving(false);
    };

    const handleDeleteAccount = async () => {
        try {
            const result = await Swal.fire({
                                               title: 'Are you sure?',
                                               text: "This action cannot be undone. All your data will be permanently deleted.",
                                               icon: 'warning',
                                               showCancelButton: true,
                                               confirmButtonColor: '#ff6b6b',
                                               cancelButtonColor: '#6b7280',
                                               confirmButtonText: 'Yes, delete my account',
                                               cancelButtonText: 'Cancel',
                                               customClass: {
                                                   popup: 'dark:bg-dark-bg dark:text-white',
                                                   title: 'dark:text-white',
                                                   content: 'dark:text-gray-300',
                                               }
                                           });

            if (result.isConfirmed) {
                setDeleting(true);
                const response = await deleteAccount();

                if (response.success) {
                    await Swal.fire({
                                        title: 'Account Deleted',
                                        text: 'Your account has been successfully deleted.',
                                        icon: 'success',
                                        customClass: {
                                            popup: 'dark:bg-gray-800 dark:text-white',
                                            title: 'dark:text-white',
                                            content: 'dark:text-gray-300',
                                        }
                                    });

                    onLogout();
                    // Clear local storage
                    localStorage.clear();

                    // Redirect to home or login page
                    navigate('/');
                }
            }
        } catch (err) {
            setDeleting(false);
            Swal.fire({
                          title: 'Error',
                          text: err.message || 'Failed to delete account. Please try again later.',
                          icon: 'error',
                          customClass: {
                              popup: 'dark:bg-gray-800 dark:text-white',
                              title: 'dark:text-white',
                              content: 'dark:text-gray-300',
                          }
                      });
        }
    };

    const handleLogout = () => {
        onLogout();

        // Clear local storage
        localStorage.clear();

        navigate('/');
    }

    const handleSendOtp = async () => {
        const response = await authApi.sendOtp(user.email);
        if(response.otp){
            setShowOtp(true);
        }
    }

    const handlePasswordChange = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }
        try {
            handleSendOtp();
        } catch (err) {
            setError('Failed to initiate password change');
        }
    };

    const handleOtpComplete = async (otp) => {
        try {
            const response = await verifyPasswordOtp({email: user.email, otp:otp})
            console.log(response);
            if(response.success){
                setShowPasswordChange(false);
                setShowOtp(false);
                setSuccess('Password changed successfully');
                handleSubmit(null);
                setTimeout(() => setSuccess(''), 3000);
            } else {
                setShowOtp(false);
                setError(response.message);
            }
        } catch (err) {
            setError('Failed to change password');
        }
    };

    return (
        <div className="max-w-[600px] mx-auto px-5 pb-48 pt-4">
            {/* Header Section */}
            <div className="flex items-center mb-6 px-3">
                <h2 className="text-lg font-semibold ml-2 text-gray-900 dark:text-gray-100">Account Settings</h2>
            </div>

            {error && (
                <div className="mx-5 mb-4 p-3 bg-red-100 dark:bg-red-900/20 text-red-700 dark:text-red-300 rounded-xxs">
                    {error}
                </div>
            )}

            {success && (
                <div className="mx-5 mb-4 p-3 bg-green-100 dark:bg-green-900/20 text-green-700 dark:text-green-300 rounded-xxs">
                    {success}
                </div>
            )}

            <div className="space-y-2">
                {/* Profile Info Section */}
                <div className="">
                    <div className="relative mb-2">
                        <div className="flex items-center flex-wrap pt-2 pb-2 pl-2 pr-2 bg-light-bg dark:bg-dark-secondary rounded-xxs gap-1">
                            <div className={`flex items-center gap-1`}>

                                <User className="h-5 w-5 text-gray-400"/>
                                <label className={`m-0 text-sm`}>Name</label>
                            </div>
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="ml-0 w-full bg-transparent text-sm text-gray-900  focus:outline-none"
                                    placeholder="Full Name"
                                />
                            </div>
                        </div>
                        <div className="relative mb-2">
                        <div className="flex items-center flex-wrap pt-2 pb-2 pl-2 pr-2 bg-light-bg dark:bg-dark-secondary rounded-xxs gap-1">
                            <div className={`flex items-center gap-1`}>
                                <BsFillPersonVcardFill className="h-5 w-5 text-gray-400"/>
                                <label className={`m-0 text-sm`}>Username</label>
                            </div>


                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                className="ml-0 w-full bg-transparent text-sm text-gray-900  focus:outline-none"
                                placeholder="Username"
                            />
                        </div>
                    </div>

                    <div className="relative mb-2">
                        <div className="flex items-center flex-wrap pt-2 pb-2 pl-2 pr-2 bg-light-bg dark:bg-dark-secondary rounded-xxs gap-1">
                            <div className={`flex items-center gap-1`}>

                                <Mail className="h-5 w-5 text-gray-400"/>
                                <label className={`m-0 text-sm`}>Email</label>
                            </div>

                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="ml-0 w-full bg-transparent text-sm text-gray-900  focus:outline-none"
                                    placeholder="Email Address"
                                />
                            </div>
                        </div>

                        {/*
                    <div className="relative mb-4">
                        <div className="flex items-center h-11 px-4 bg-gray-100 dark:bg-gray-800 rounded-xxs">
                            <MapPin className="h-5 w-5 text-gray-400" />
                            <input
                                type="text"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                className="ml-3 w-full bg-transparent text-sm text-gray-900 dark:text-white focus:outline-none"
                                placeholder="Default Location"
                            />
                        </div>
                    </div> */}


                </div>

                {/* Settings Links */}
                <div className=" mt-6">
                    <button
                        onClick={() => setShowPasswordChange(true)}
                        className="w-full flex items-center justify-between h-11 px-4 bg-light-bg dark:bg-dark-secondary rounded-xxs mb-2 border-0  cursor-pointer"
                    >
                        <div className="flex items-center">
                            <Lock className="h-5 w-5 text-gray-400"/>
                            <span className="ml-3 text-sm text-gray-900 dark:text-white">Change Password</span>
                        </div>
                        <ChevronRight className="h-5 w-5 text-gray-400"/>
                    </button>

                    <button
                        onClick={handleSubmit}
                        disabled={saving}
                        className={`${saving ? 'bg-gray-600' : 'bg-brand-purple hover:bg-purple-700'} w-full h-11   text-white rounded-xxs text-sm transition-colors border-0  cursor-pointer`}
                    >
                        {saving ?
                         <div className={`flex flex-row gap-2 items-center justify-center`}>

                             <span className={`flex flex-row gap-2 items-center justify-center`}>Saving changes... <Loader2 className="w-4 h-4 animate-spin"/></span>
                         </div>
                                :
                         <span>Save Changes</span>
                        }

                    </button>
                </div>

                {/* Legal Links */}
                <div className=" mt-4 flex gap-2">
                    <a
                        href={constants.PRIVACY_URL}
                        target="_blank"
                        className="flex-1 h-11 flex items-center justify-center bg-light-bg dark:bg-dark-secondary  rounded-xxs text-sm text-gray-900 dark:text-white"
                    >
                        Privacy Policy
                    </a>
                    <a
                        href={constants.TOS_URL}
                        target="_blank"
                        className="flex-1 h-11 flex items-center justify-center bg-light-bg dark:bg-dark-secondary rounded-xxs text-sm text-gray-900 dark:text-white"
                    >
                        Terms of Use
                    </a>
                    <button
                        onClick={handleDeleteAccount}
                        disabled={deleting}
                        className="flex-1 h-11 flex items-center justify-center bg-badge-delete rounded-xxs text-sm text-white border-0 hover:bg-red-200 dark:hover:bg-red-900/40 transition-colors  cursor-pointer"
                    >
                        {deleting ?
                            <div className="flex items-center gap-2">
                                <Loader2 className="w-4 h-4 animate-spin"/>
                                <span>Deleting...</span>
                            </div>
                         :
                             'Delete Account'
                         }
                    </button>
                </div>

                <div className="px-5 mt-4 flex gap-2">


                            <div className={styles.logoutWrap}>
                                <Link className={`${styles.menuLink} ${styles.logout}`}>
                                    <div className={`${styles.iconAndName} ${styles.logoutText}`} onClick={handleLogout}>
                                        <CiLogout className={`${styles.menuIcon} ${styles.logoutIcon}`}/>
                                        Sign Out
                                    </div>
                                </Link>
                            </div>


                </div>
            </div>

            {/* Password Change Modal */}
            {showPasswordChange && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 w-full box-border">
                    <div className="bg-white dark:bg-dark-bg rounded-xxs p-6 max-w-lg w-80">
                        <div className="flex items-center mb-4">
                            <a onClick={() => setShowPasswordChange(false)} className="p-2">
                                <ArrowLeft className="h-6 w-6 text-gray-500 dark:text-gray-400" />
                            </a>
                            <h3 className="text-lg font-semibold ml-2 text-gray-900 dark:text-white">
                                Change Password
                            </h3>
                        </div>

                        {showOtp ? (
                            <div className="space-y-4">
                                <p className="text-sm text-gray-600 dark:text-gray-300">
                                    Please enter the verification code sent to your email
                                </p>
                                <OTPInput onComplete={handleOtpComplete} />
                            </div>
                        ) : (
                             <form onSubmit={handlePasswordChange} className="space-y-3">
                                 <div className="flex items-center h-11 pl-4 bg-gray-100 dark:bg-dark-secondary rounded-xxs">
                                     <Lock className="h-5 w-5 text-gray-400"/>
                                     <input
                                         type="password"
                                         value={newPassword}
                                         onChange={(e) => setNewPassword(e.target.value)}
                                         className="ml-3 w-full bg-transparent text-sm text-gray-900 focus:outline-none"
                                         placeholder="New Password"
                                     />
                                 </div>

                                 <div className="flex items-center h-11 pl-4 bg-gray-100 dark:bg-dark-secondary rounded-xxs">
                                     <Lock className="h-5 w-5 text-gray-400" />
                                     <input
                                         type="password"
                                         value={confirmPassword}
                                         onChange={(e) => setConfirmPassword(e.target.value)}
                                         className="ml-3 w-full bg-transparent text-sm text-gray-900 focus:outline-none"
                                         placeholder="Confirm New Password"
                                     />
                                 </div>

                                 <button
                                     type="submit"
                                     className="w-full h-11 bg-brand-purple hover:bg-purple-700 border-0 text-white rounded-xxs text-sm transition-colors"
                                 >
                                     Update Password
                                 </button>
                             </form>
                         )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default AccountSettings;
